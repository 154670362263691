import type {
  AxiosError,
} from 'axios';
import axios from 'axios';
import * as jQuery from 'jquery';
import {
  createPinia,
} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {
  createApp,
} from 'vue';
import App from '@/App.vue';
import {
  ROUTE_API,
  baseUrl,
} from '@/helpers';
import {
  UtilityCommands,
} from '@/plugins/utility';
import router from '@/router';
import {
  registerStore,
  resetStore,
  useStore,
} from '@/store';
import '@/style/css/index.css';
import '@/style/scss/index.scss';
import 'katex/dist/katex.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'mathquill4quill/mathquill4quill.css';

window.jQuery = jQuery;
window.$ = jQuery;

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.config.errorHandler = (err, vm, info): void => {
  console.error(`Error in ${info}:`, err);

  const authStore = useStore.auth;

  const { username = 'GUEST', company = 'NONE' } = authStore.user || {
  };

  const userInfo = `${company} - ${username}`;

  const data = {
    message: `[${userInfo}] ${(err as Error).message}`,
    info,
    stack: (err as Error).stack,
  };
  axios.post('/errors', data)
    .catch(console.error);
};

axios.defaults.baseURL = `${baseUrl}${ROUTE_API}`;

axios.interceptors.response.use(undefined, async (error) => {
  const errResponse = (error as AxiosError).response;
  const authStore = useStore.auth;

  if (errResponse?.status === 401 && errResponse?.data === 'Unauthorized') {
    authStore.logout();
    if (router.currentRoute.value.fullPath !== '/') {
      await router.push('/');
    }
  }

  return Promise.reject(error);
});

app.use(router);
app.use(UtilityCommands);

resetStore();
registerStore();

app.mount('#app');
