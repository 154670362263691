<!--
 - Ankur Mursalin
 - encryptioner.github.io
 - December 08, 2021
 -->

<template>
  <div class="relative min-h-screen flex-col font-balooDa2">
    <main>
      <template
        v-if="appStore.isBootstrapped"
      >
        <BusNotification />
      </template>

      <div>
        <CustomLoader1
          v-if="appStore.isLoading || !appStore.isBootstrapped"
          class="modal modal-open"
        />
        <ScrollToTopButton />
        <RouterView
          v-if="appStore.isBootstrapped"
        />
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>

import {
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from 'vue';
import {
  viewportBreakpoint,
} from './helpers';
import BusNotification from '@/components/App/BusNotification.vue';
import ScrollToTopButton from '@/components/Helper/Button/ScrollToTopButton.vue';
import CustomLoader1 from '@/components/Helper/Loader/CustomLoader1.vue';
import {
  useStore,
} from '@/store';

const appStore = useStore.app;
const authStore = useStore.auth;
const backendStore = useStore.backend;

watch(() => authStore.token, (newVal, oldVal) => {
  const triggerAuthChangeHandler = (newVal && !oldVal) || (oldVal && !newVal);
  if (triggerAuthChangeHandler) {
    backendStore.initOnAuthChange();
  }
});

watch(() => appStore.isBootstrapped, (newVal) => {
  if (!newVal) {
    return;
  }
  _updateManifest();
});

onBeforeMount(() => {
  window.addEventListener('storage', _storageEventHandler);
  window.addEventListener('resize', _resizedBreakpointEventHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('storage', _storageEventHandler);
  window.removeEventListener('resize', _resizedBreakpointEventHandler);
});

const _storageEventHandler = (e: StorageEvent): void => {
  if (e.key === 'token') {
    // local storage token changed
    console.log(`Key Changed: ${e.key}`);
    window.sessionStorage.removeItem('token');
    window.location.reload();
  }
};

const _resizedBreakpointEventHandler = (e: Event): void => {
  const resizedBreakpoint = viewportBreakpoint();
  if (resizedBreakpoint !== appStore.breakpoint) {
    appStore.breakpoint = resizedBreakpoint;
  }
};

const _updateManifest = async (): Promise<void> => {
  const manifestLink = document.querySelector('link[rel="manifest"]') as HTMLLinkElement | null;

  if (!manifestLink) {
    return;
  }

  try {
    interface IManifestIcon {
      src: string;
      sizes: string;
      type: string;
    }

    interface IManifest {
      name?: string;
      short_name?: string;
      start_url?: string;
      display?: string;
      background_color?: string;
      theme_color?: string;
      icons?: IManifestIcon[];
    }

    // Fetch the existing manifest
    const response = await fetch(manifestLink.href);
    const manifest: IManifest = await response.json();

    const { name, publicWebsite } = backendStore.clientCompany;
    const { iconsPath } = backendStore.clientCompany.imageSettings;

    manifest.name = name;
    manifest.short_name = name;
    manifest.start_url = publicWebsite;
    manifest.icons = manifest.icons?.map((icon) => ({
      ...icon,
      src: icon.src.replace('./img/icons', iconsPath),
    }));

    // Create a new Blob for the updated manifest
    const updatedManifestBlob = new Blob([JSON.stringify(manifest)], {
      type: 'application/json',
    });

    // Update the manifest link's href
    manifestLink.href = URL.createObjectURL(updatedManifestBlob);
  } catch (error) {
    console.error('Failed to update manifest:', error);
  }
};

</script>
