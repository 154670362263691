import {
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  hasRouteAccess,
  routes,
} from './routes';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  CONSTANTS,
  IS_DEVELOPMENT,
  redirectUserAfterLogin,
} from '@/helpers';
import {
  useStore,
} from '@/store';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  useStore.app.prevRoutePath = from.fullPath;
  useStore.app.isLoading = true;

  // ensuring user load from local storage attempted before routing
  await useStore.app.init();
  next();
});

router.afterEach((to, from, failure) => {
  useStore.app.isLoading = false;
});

router.beforeResolve((to, from, next) => {
  const { user } = useStore.auth;
  const { name: appName } = useStore.backend.clientCompany;

  let titleSuffix = user && IS_DEVELOPMENT
    ? ` | ${user.firstName}`
    : '';

  if (to.meta?.title) {
    titleSuffix = ` - ${to.meta?.title}${titleSuffix}`;
  }
  document.title = `${appName}${titleSuffix}`;

  // Redirect unauthenticated users to the login page
  if (!user) {
    if (to.fullPath !== CONSTANTS.ROUTES.GUEST_HOME) {
      next(CONSTANTS.ROUTES.GUEST_HOME);
      return;
    }
    next();
    return;
  }

  const hasAccess = hasRouteAccess(to.path, user.role);
  if (!hasAccess) {
    Bus.emit(NOTIFICATION.INFO, {
      message: `Sorry, you can't access the page ${to.path}`,
    });
  }

  const shouldRedirectToMemberHome = to.meta?.isGuest || !hasAccess || to.path === '/';

  if (shouldRedirectToMemberHome) {
    next(redirectUserAfterLogin(user).route);
    return;
  }

  // Allow authenticated users to proceed
  next();
});

export { routes };

export default router;
